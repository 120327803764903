import Constant from './Constant';
import ItemStatusColor from './ItemStatusColor';

export const TmpHiddenSystemField = [
    'guestOrg',
    'guestProject',
    'adminMailAdress',
    'adminLastName',
    'adminFirstName',
    'category',
    'description',
    'customerEmail',
    'customerLastName',
    'customerFirstName',
    'field_qa_category',
    'priority',
    'knowledgeCanvas',
    'bookmark',
    'deadline',
    'point',
    'footPrint',
    'maxGeneralId',
    'pageNo',
    'chapter',
    'section',
    'subSection',
    'item',
    'from',
    'to',
    'cc',
    'bcc',
    'mailSubject',
    'mailBody'
]

export const HiddenSysFieldInFieldList = [
    'AIPromptBody',
    'ApplyAIprompt',
    'AIPromptApplicationTarget',
]


export const ForceActiveSystemFields = [
    'attachments', 
    'ticketId',
    'ticketType',	
    'footPrint',
    'proxiedUser',
    'parent',
    'children',
    'escalationOriginCustomer',
    'escalationTarget',
    'personInCharge', 
    'AIPromptBody',
    'ApplyAIprompt',
    'AIPromptApplicationTarget',
    'listOfSynonyms',
    'from',
    'to',
    'cc',
    'bcc',
    'mailSubject',
    'mailBody'
]

export const TmpHiddenSysFieldInTicketDetail = [
    'ticketId',
    'ticketType',	
    'footPrint',
    'proxiedUser',
    'children',
    'parent',
    // 'escalationOriginCustomer',
    // 'escalationTarget',
    // 'personInCharge',
    // 'field_qa_category' 
]

export const TmpHiddenSysfieldInCreateTicket = [
    'ticketId',
    'ticketType',	
    'footPrint',
    'proxiedUser',
    'parent',
    'children',
    'escalationOriginCustomer',
    'escalationTarget',
    'personInCharge',
    // 'field_qa_category' ,
    'pageNo',
    'chapter',
    'section',
    'subSection',
    'item',
    'from',
]

export const HasExpandSysField = [
    'attachments',
    'ticketId',
    'personInCharge',
    'proxiedUser',
    'outline',
    'ticketType',	
    'footPrint',
    'parent',
    'children',
    'escalationOriginCustomer',
    'escalationTarget',
    'AIPromptBody',
    'ApplyAIprompt',
    'AIPromptApplicationTarget',
    'listOfSynonyms'
]

export const AutoPassSystemFieldInSchema = [
    'escalationOriginCustomer',
    'escalationTarget',
    'from',
    'to',
]

export const PageSystemFields = [
    'pageNo',
    'chapter',
    'section',
    'subSection',
    'item',
]

export const AISystemField = [
    'AIPromptBody',
    'ApplyAIprompt',
    'AIPromptApplicationTarget'
]

export const IgnoreIndexSystemTagField = [
    'field_tag_and_ai_auto',
    'field_tag_or_ai_auto',
    'field_tag_exclude_ai_auto'
]

export const IgnoreBulkActionField = [
    'field_ticket_type_active_ai_auto',
    'field_form_active_ai_auto',
    'field_tag_and_ai_auto',
    'field_tag_or_ai_auto',
    'field_tag_exclude_ai_auto',
];

export const AutoPassForBulkAction = [
    'ApplyAIprompt',
    'field_event_trigger_ai_auto',
    'field_hours_execute_ai_auto',
    'field_minutes_execute_ai_auto',
    'AIPromptApplicationTarget',
];

export const systemField = [
    {
        id: 'guestOrg',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '推薦ゲスト組織',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'guestOrg',
        noInFormInvite: 1,
    },
    {
        id: 'guestProject',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '推薦パートナープロジェクト',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'guestProject',
        noInFormInvite: 2,
    },
    {
        id: 'adminMailAdress',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '代表者アドレス',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true,
            regExpr: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'adminMailAdress',
        noInFormInvite: 3,
    },
    {
        id: 'adminLastName',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '代表者姓',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'adminLastName',
        noInFormInvite: 4,
    },
    {
        id: 'adminFirstName',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '代表者名',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'adminFirstName',
        noInFormInvite: 5,
    },
    {
        id: 'outline',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '概要',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            maxLength: 2000,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'outline',
        // noInFormInvite: 6,
        noInFormQA: 1,
        noInFormExternalDeskRequest: 1,
        noInFormGuestCustomerInvitation: 4,
        noInFormExternalCustomerRequest: 1,
        noInFormFileFormat: 3,
        noInFormPageFormat: 3,
        noInFormAIChatPrompt: 1,
    },
    {
        id: 'category',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'カテゴリー',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            maxLength: 50
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'category',
        // noInFormInvite: 7,
        noInFormExternalDeskRequest: 2,
        noInFormGuestCustomerInvitation: 5,
        noInFormExternalCustomerRequest: 2,
    },
    {
        id: 'description',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '詳細',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT_AREA,
            elasticSearch: '',
            maxLength: 2000
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'description',
        // noInFormInvite: 8,
        noInFormQA: 3,
        noInFormExternalDeskRequest: 3,
        noInFormGuestCustomerInvitation: 6,
        noInFormExternalCustomerRequest: 3,
    },
    {
        id: 'attachments',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '添付ファイル',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.DROP_FILE,
            elasticSearch: '',
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'attachments',
        // noInFormInvite: 9,
        noInFormQA: 4,
        noInFormGuestCustomerInvitation: 7,
        noInFormFileFormat: 5,
        noInFormPageFormat: 9,
        noInFormEmail: 8
    },

    // QA form area
    // QA tag field category
    {
        id: 'field_qa_category',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '問い合わせカテゴリー',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        structure: {
            UI: Constant.FIELD_UI.TAG,
            isMultiple: false,
            optionType: Constant.TAG_FIELD_DEFAULT.SELECT_OPTION_TYPE,
            recordNumber: Constant.TAG_FIELD_DEFAULT.RECORD_NUMBER,
            isAddItem: false,
            settingSelectItem: Constant.SETTING_SELECT_ITEM_TAG_FIELD.DEFAULT,
            displayOrder: Constant.DISPLAY_ORDER_TAG_FIELD.DEFAULT,
            elasticSearch: '',
            required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category',
        noInFormQA: 2,
    },

    // QA tag item field category
    {
        id: 'field_qa_category_tag_requirement',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '商品仕様',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 1,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_requirement',
    },
    {
        id: 'field_qa_category_tag_price',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '価格',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 2,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_price',
    },
    {
        id: 'field_qa_category_tag_quotation',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '見積依頼',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 3,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_quotation',
    },
    {
        id: 'field_qa_category_tag_cancel',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: 'キャンセル',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 4,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_cancel',
    },
    {
        id: 'field_qa_category_tag_shipping_delivery',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '送料＆配送',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 5,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_shipping_delivery',
    },
    {
        id: 'field_qa_category_tag_payment',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '支払い',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 6,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_payment',
    },
    {
        id: 'field_qa_category_tag_business_day',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '営業日',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 7,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_business_day',
    },
    {
        id: 'field_qa_category_tag_operation_setting_method',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '操作＆設定方法',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 8,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_operation_setting_method',
    },
    {
        id: 'field_qa_category_tag_troubleshooting',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: 'トラブル対応',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 9,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_troubleshooting',
    },
    {
        id: 'field_qa_category_tag_order',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '注文',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 10,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_order',
    },
    {
        id: 'field_qa_category_tag_document_request',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '資料希望',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 11,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_document_request',
    },
    {
        id: 'field_qa_category_tag_document_others',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: 'その他',
            },
        },
        affiliationField: 'field_qa_category',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 12,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_qa_category_tag_document_others',
    },

    // field status as new project created
    // {
    //     id: 'field_status',
    //     usage: Constant.FORM_USAGE.FIELD,
    //     name: {
    //         jp: {
    //             A: 'F',
    //             text: 'ステータス',
    //         },
    //     },
    //     type: Constant.FORM_TYPE.FIELD_WORKFLOW,
    //     structure: {
    //         UI: Constant.FIELD_UI.SELECTOR,
    //         elasticSearch: '',
    //         workFlow: false,
    //     },
    //     definition: Constant.FORM_DEFINITION.SYSTEM,
    //     sysfield: 'field_status',
    //     noInFieldStatus: 0,
    // noInFormEmail: 2
    // },

    // items of field status
    {
        id: 'field_status_waiting',
        usage: Constant.FORM_USAGE.STATUS,
        name: {
            jp: {
                A: 'F',
                text: 'WAITING',
            },
        },
        affiliationField: 'field_status',
        type: '',
        structure: {
            helpdesk: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            customer: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            no: 1,
            isUsingItem: true,
        },
        viewForCustomer: {
            jp: {
                A: 'F',
                text: 'WAITING',
            },
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_status_waiting',
        noInFieldStatus: 1,
    },
    {
        id: 'field_status_todo',
        usage: Constant.FORM_USAGE.STATUS,
        name: {
            jp: {
                A: 'F',
                text: 'TODO',
            },
        },
        affiliationField: 'field_status',
        type: '',
        structure: {
            helpdesk: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            customer: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            no: 2,
            isUsingItem: true,
        },
        viewForCustomer: {
            jp: {
                A: 'F',
                text: 'TODO',
            },
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_status_todo',
        noInFieldStatus: 2,
    },
    {
        id: 'field_status_doing',
        usage: Constant.FORM_USAGE.STATUS,
        name: {
            jp: {
                A: 'F',
                text: 'DOING',
            },
        },
        affiliationField: 'field_status',
        type: '',
        structure: {
            helpdesk: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            customer: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            no: 3,
            isUsingItem: true,
        },
        viewForCustomer: {
            jp: {
                A: 'F',
                text: 'DOING',
            },
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_status_doing',
        noInFieldStatus: 3,
    },
    {
        id: 'field_status_done',
        usage: Constant.FORM_USAGE.STATUS,
        name: {
            jp: {
                A: 'F',
                text: 'DONE',
            },
        },
        affiliationField: 'field_status',
        type: '',
        structure: {
            helpdesk: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            customer: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            no: 4,
            isUsingItem: true,
        },
        viewForCustomer: {
            jp: {
                A: 'F',
                text: 'DONE',
            },
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_status_done',
        noInFieldStatus: 4,
    },
    {
        id: 'field_status_pending',
        usage: Constant.FORM_USAGE.STATUS, 
        name: {
            jp: {
                A: 'F',
                text: 'PENDING',
            },
        },
        affiliationField: 'field_status',
        type: '',
        structure: {
            helpdesk: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            customer: {
                color: ItemStatusColor[0].color,
                background: ItemStatusColor[0].background,
            },
            no: 5,
            isUsingItem: true,
        },
        viewForCustomer: {
            jp: {
                A: 'F',
                text: 'PENDING',
            },
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'field_status_pending',
        noInFieldStatus: 5,
    },


    // Field system form guest customer invitation
    {
        id: 'customerEmail',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '登録カスタマーアドレス',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true,
            regExpr: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'customerEmail',
        noInFormGuestCustomerInvitation: 1,
    },
    {
        id: 'customerLastName',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'カスタマー姓',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'customerLastName',
        noInFormGuestCustomerInvitation: 2,
    },
    {
        id: 'customerFirstName',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'カスタマー名',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'customerFirstName',
        noInFormGuestCustomerInvitation: 3,
    },

    // New systemField
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'チケットID',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
            publicForCustomer: Constant.SETTING_FORM_DEFAULT.READ_ONLY,
            disablePublicForCustomer: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'ticketId',
        id: 'ticketId',
        noInFormQA: 9,
        noInFormFileFormat: 1,
        noInFormPageFormat: 1,
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'チケットタイプ',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
            publicForCustomer: Constant.SETTING_FORM_DEFAULT.DO_NOT_SHOW,
            disablePublicForCustomer: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'ticketType',
        id: 'ticketType',
        noInFormQA: 10,
        noInFormFileFormat: 2,
        noInFormPageFormat: 2
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '足跡',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true'
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'footPrint',
        id: 'footPrint',
        
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '代理投稿者',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'proxiedUser',
        id: 'proxiedUser',
        noInFormQA: 7
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '親チケット',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
            publicForCustomer: Constant.SETTING_FORM_DEFAULT.DO_NOT_SHOW,
            disablePublicForCustomer: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'parent',
        id: 'parent',
        noInFormQA: 12,
        noInFormFileFormat: 6,
        noInFormPageFormat: 10
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '子チケット',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
            publicForCustomer: Constant.SETTING_FORM_DEFAULT.DO_NOT_SHOW,
            disablePublicForCustomer: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'children',
        id: 'children',
        noInFormQA: 8,
        noInFormFileFormat: 7,
        noInFormPageFormat: 11
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'エスカレーション元のカスタマー',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
            publicForCustomer: Constant.SETTING_FORM_DEFAULT.READ_ONLY,
            disablePublicForCustomer: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'escalationOriginCustomer',
        id: 'escalationOriginCustomer',
        noInFormQA: 11
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'エスカレーション先',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            isAutoAddData: 'true',
            isAutoFill: 'true',
            required: true,
            disableSettingRequired: true,
            publicForCustomer: Constant.SETTING_FORM_DEFAULT.DO_NOT_SHOW,
            disablePublicForCustomer: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'escalationTarget',
        id: 'escalationTarget',
        noInFormQA: 5
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '担当者',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            disableSettingRequired: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'personInCharge',
        id: 'personInCharge',
        noInFormQA: 6,
        noInFormEmail: 1,
    },


    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '案件緊急度',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'priority',
        id: 'priority',
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'しおり',
            },
        },
        type: Constant.FORM_TYPE.FIELD_RICHTEXT,
        structure: {
            UI: Constant.FIELD_UI.FIELD_RICHTEXT,
            elasticSearch: '',
            maxLength: Constant.RICHTEXT_MAX_LENGTH,
            minLength: 0
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'bookmark',
        id: 'bookmark',
        noInFormFileFormat: 4,
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '期限',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'deadline',
        id: 'deadline',
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'ポイント',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'point',
        id: 'point',
    },
    {
        id: 'maxGeneralId',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '利用可能なジェネラルユーザーID数',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true,
            regExpr: /^[0-9]+$/,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'maxGeneralId',
        noInFormInviteGuestDesk: 10,
    },
    // field AI-Prompt Body
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'AI-プロンプト本文',
            },
        },
        type: Constant.FORM_TYPE.FIELD_RICHTEXT,
        structure: {
            UI: Constant.FIELD_UI.FIELD_RICHTEXT,
            elasticSearch: '',
            maxLength: Constant.RICHTEXT_MAX_LENGTH,
            minLength: 0
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'AIPromptBody',
        id: 'AIPromptBody',
        noInFormAIChatPrompt: 3
    },
    // field Apply AI-prompt
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'AI-プロンプト適用',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        structure: {
            UI: Constant.FIELD_UI.TAG,
            elasticSearch: '',
            required: true,
            disableSettingRequired: true,
            optionType: Constant.TAG_FIELD_DEFAULT.CHECK_OPTION_TYPE,
            isMultiple: false,
            recordNumber: 2,
            isAddItem: false,
            settingSelectItem: false,
            
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'ApplyAIprompt',
        id: 'ApplyAIprompt',
        noInFormAIChatPrompt: 2
    },
    // field option Apply AI-prompt
    {
        id: 'ApplyAIpromptOff',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: 'OFF',
            },
        },
        affiliationField: 'ApplyAIprompt',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 1,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'ApplyAIpromptOff',
    },
    {
        id: 'ApplyAIpromptOn',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: 'ON',
            },
        },
        affiliationField: 'ApplyAIprompt',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 2,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'ApplyAIpromptOn',
    },
    // field AI-Prompt application target
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'AI-プロンプト適用先',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        structure: {
            UI: Constant.FIELD_UI.TAG,
            elasticSearch: '',
            optionType: Constant.TAG_FIELD_DEFAULT.CHECK_OPTION_TYPE,
            isMultiple: true,
            recordNumber: 2,
            isAddItem: false,
            settingSelectItem: Constant.SETTING_SELECT_ITEM_TAG_FIELD.DEFAULT,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'AIPromptApplicationTarget',
        id: 'AIPromptApplicationTarget',
        noInFormAIChatPrompt: 4
    },
    // field option AI-Prompt application target
    {
        id: 'AIPromptApplicationTargetInternal',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '内部',
            },
        },
        affiliationField: 'AIPromptApplicationTarget',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 1,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'AIPromptApplicationTargetInternal',
    },
    {
        id: 'AIPromptApplicationTargetExternal',
        usage: Constant.FORM_USAGE.TAG,
        name: {
            jp: {
                A: 'F',
                text: '外部',
            },
        },
        affiliationField: 'AIPromptApplicationTarget',
        type: '',
        structure: {
            isShowItemInTicket: true,
            no: 2,
        },
        viewForCustomer: {},
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'AIPromptApplicationTargetExternal',
    },
    // field ページ番号 <Page No>
    {
        id: 'pageNo',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'ページ番号',
            },
        },
        type: Constant.FORM_TYPE.FIELD_INT,
        structure: {
            UI: Constant.FIELD_UI.NUMBER,
            elasticSearch: '',
            required: true,
            regExpr: /^[0-9]+$/,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'pageNo',
        noInFormPageFormat: 4,
    },
    // field 章 <Chapter>
    {
        id: 'chapter',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '章',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        isRequiedPageKnowledgeField: true,
        dataValue: '',
        structure: {
            UI: Constant.FIELD_UI.TAG,
            optionType: Constant.TAG_FIELD_DEFAULT.SELECT_OPTION_TYPE,
            recordNumber: Constant.TAG_FIELD_DEFAULT.RECORD_NUMBER,
            settingSelectItem: Constant.SETTING_SELECT_ITEM_TAG_FIELD.DEFAULT,
            displayOrder: Constant.DISPLAY_ORDER_TAG_FIELD.DEFAULT,
            isMultiple: false,
            elasticSearch: '',
            // isAddItem: false,
            // required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'chapter',
        noInFormPageFormat: 5,
    },
    // field 節 <Section>
    {
        id: 'section',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '節',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        isRequiedPageKnowledgeField: true,
        dataValue: '',
        structure: {
            UI: Constant.FIELD_UI.TAG,
            optionType: Constant.TAG_FIELD_DEFAULT.SELECT_OPTION_TYPE,
            recordNumber: Constant.TAG_FIELD_DEFAULT.RECORD_NUMBER,
            settingSelectItem: Constant.SETTING_SELECT_ITEM_TAG_FIELD.DEFAULT,
            displayOrder: Constant.DISPLAY_ORDER_TAG_FIELD.DEFAULT,
            isMultiple: false,
            elasticSearch: '',
            // isAddItem: false,
            // required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'section',
        noInFormPageFormat: 6,
    },
    // field 項 <Sub-Section>
    {
        id: 'subSection',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '項',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        isRequiedPageKnowledgeField: true,
        dataValue: '',
        structure: {
            UI: Constant.FIELD_UI.TAG,
            optionType: Constant.TAG_FIELD_DEFAULT.SELECT_OPTION_TYPE,
            recordNumber: Constant.TAG_FIELD_DEFAULT.RECORD_NUMBER,
            settingSelectItem: Constant.SETTING_SELECT_ITEM_TAG_FIELD.DEFAULT,
            displayOrder: Constant.DISPLAY_ORDER_TAG_FIELD.DEFAULT,
            isMultiple: false,
            elasticSearch: '',
            // isAddItem: false,
            // required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'subSection',
        noInFormPageFormat: 7,
    },
    // field 目 <Item>
    {
        id: 'item',
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '目',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TAG,
        isRequiedPageKnowledgeField: true,
        dataValue: '',
        structure: {
            UI: Constant.FIELD_UI.TAG,
            optionType: Constant.TAG_FIELD_DEFAULT.SELECT_OPTION_TYPE,
            recordNumber: Constant.TAG_FIELD_DEFAULT.RECORD_NUMBER,
            settingSelectItem: Constant.SETTING_SELECT_ITEM_TAG_FIELD.DEFAULT,
            displayOrder: Constant.DISPLAY_ORDER_TAG_FIELD.DEFAULT,
            isMultiple: false,
            elasticSearch: '',
            // isAddItem: false,
            // required: true,
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'item',
        noInFormPageFormat: 8,
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '類義語一覧',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT_AREA,
            elasticSearch: '',
            required: false,
            maxLength: 20000,
            minLength: 0
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'listOfSynonyms',
        id: 'listOfSynonyms',
    },

    // Email ticket
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'From',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: '',
            required: true
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'from',
        id: 'from',
        noInFormEmail: 3
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'To',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT_WITH_CHIP,
            elasticSearch: '',
            required: true
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'to',
        id: 'to',
        noInFormEmail: 4
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'Cc',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT_WITH_CHIP,
            elasticSearch: ''
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'cc',
        id: 'cc',
        noInFormEmail: 5
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: 'Bcc',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT_WITH_CHIP,
            elasticSearch: ''
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'bcc',
        id: 'bcc',
        noInFormEmail: 6
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '件名',
            },
        },
        type: Constant.FORM_TYPE.FIELD_TEXT,
        structure: {
            UI: Constant.FIELD_UI.TEXT,
            elasticSearch: ''
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'mailSubject',
        id: 'mailSubject',
        noInFormEmail: 7
    },
    {
        usage: Constant.FORM_USAGE.FIELD,
        name: {
            jp: {
                A: 'F',
                text: '本文',
            },
        },
        type: Constant.FORM_TYPE.FIELD_RICHTEXT,
        structure: {
            UI: Constant.FIELD_UI.FIELD_RICHTEXT,
            elasticSearch: '',
            maxLength: 20000
        },
        definition: Constant.FORM_DEFINITION.SYSTEM,
        sysfield: 'mailBody',
        id: 'mailBody',
        noInFormEmail: 8
    }
];

export default systemField;
